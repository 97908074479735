import Vue from "vue";
import VueRouter from "vue-router";

const index = () => import("@/views/index.vue");

Vue.use(VueRouter); //如果已引用，不需要重复引用
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

const router = new VueRouter({
  mode: "history", //hash
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "/index",
      meta: {
        keepAlive: true, // 是否缓存标志
        title: "列表页"
      }
    },
    {
      path: "/index",
      name: "index",
      component: index,
      meta: {
        keepAlive: true, // 是否缓存标志
        title: "列表页"
      }
    }
  ]
});
export default router;
